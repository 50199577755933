import React from 'react'
import Paper from '../paper/paper'

export const AboutMe = () => {
  return (
    <Paper > 
      En construcción...
    </Paper>
  )
}
