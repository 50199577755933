import esLang from './es'
import enLang from './en'

export default {
  es: {
    ...esLang
  },
  en: {
    ...enLang
  }
}
