import React from "react";
import { PersonalInformation } from "../components/home/personalInformation";

export const HomeScreen = () => {
  return (
    <>
      <PersonalInformation />
    </>
  );
};
